/* Font face declarations should be compassified */
@font-face {
  font-family: 'spoiler';
  src: url('#{$netstorage-font-path}Spoiler/Spoiler.woff2') format('woff2');
  font-display: swap;
}

@font-face {
  font-family: 'spoilerlight';
  src: url('#{$netstorage-font-path}Spoiler/Spoiler-Light.woff2') format('woff2');
  font-display: swap;
}

@font-face {
  font-family: 'spoiler-regular';
  src: url('#{$netstorage-font-path}Spoiler/Spoiler-Regular.woff2') format('woff2');
  font-display: swap;
}

@font-face {
  font-family: 'spoiler-bold';
  src: url('#{$netstorage-font-path}Spoiler/Spoiler-Bold.woff2') format('woff2');
  font-display: swap;
}

@font-face {
  font-family: 'spoilerblack';
  src: url('#{$netstorage-font-path}Spoiler/Spoiler-Black.woff2') format('woff2');
  font-display: swap;
}

html,
body,
button,
input,
select,
textarea {
  font-family: HelveticaNeueLTStd55Roman, spoiler, Verdana, Arial, Sans-Serif;
}

a {
  &.button {
    font-family: HelveticaNeueLTStd75Bold, 'spoiler-bold', Verdana, Arial, Sans-Serif;
  }
}

input.form-submit,
input.button[type='submit'] {
  @include a-button;
}

h1,
.h1,
h2,
.h2,
h3,
.h3,
h4,
.h4,
h5,
.h5 {
  font-family: HelveticaNeueLTStd55Roman, spoiler, Verdana, Arial, Sans-Serif;
}

h1,
.h1 {
  font-size: 40px;
}

h2,
.h2 {
  font-size: 36px;
}

div,
ul,
li,
a,
p,
span,
table,
tr,
th,
td,
input,
form,
h1,
h2,
h3,
h4,
h5,
dl,
dt,
dd,
label,
small,
select,
article {
  font-family: HelveticaNeueLTStd55Roman, spoiler, Verdana, Arial, Sans-Serif !important;
  &.valid_marker,
  .invalid_marker {
    font-family: Arial Unicode MS !important;
  }
}

strong,
b {
  font-family: HelveticaNeueLTStd75Bold, 'spoiler-bold', Verdana, Arial, Sans-Serif;
}

.content-block {
  font-family: HelveticaNeueLTStd55Roman, spoiler, Verdana, Arial, Sans-Serif !important;
  a {
    font-family: HelveticaNeueLTStd55Roman, spoiler, Verdana, Arial, Sans-Serif !important;
  }
  b,
  strong {
    font-family: HelveticaNeueLTStd75Bold, 'spoiler-bold', Verdana, Arial, Sans-Serif !important;
  }
}

.header-gnav-links__title,
.header-gnav-section__label,
.header-gnav-section__link,
.header-gnav-section__panel-link--header {
  font-family: HelveticaNeueLTStd55Roman, spoiler, Verdana, Arial, Sans-Serif !important;
}

.header-gnav-links .header-gnav-link {
  font-family: HelveticaNeueLTStd55Roman, spoiler, Verdana, Arial, Sans-Serif !important;
}

.gnav-header-formatter__bottom .header-gnav-link {
  font-family: HelveticaNeueLTStd55Roman, spoiler, Verdana, Arial, Sans-Serif !important;
}

.elc-product-full {
  .elc-product-overview {
    h4,
    h5 {
      font-family: HelveticaNeueLTStd75Bold, 'spoiler-bold', Verdana, Arial, Sans-Serif !important;
    }
  }
}

.sd-product-spp {
  .elc-product-cross-sell-header,
  .elc-product-full-sticky-menu .elc-product-original-price {
    font-family: HelveticaNeueLTStd75Bold, 'spoiler-bold', Verdana, Arial, Sans-Serif !important;
  }
}

.title--large,
.title--large p,
h1.title--large,
h2.title--large,
h3.title--large,
h4.title--large,
h5.title--large,
h6.title--large,
p.title--large {
  font-family: HelveticaNeueLTStd55Roman, spoiler, Verdana, Arial, Sans-Serif !important;
}

.title--xl,
.title--xl p,
h1.title--xl,
h2.title--xl,
h3.title--xl,
h4.title--xl,
h5.title--xl,
h6.title--xl,
p.title--xl {
  font-family: HelveticaNeueLTStd55Roman, spoiler, Verdana, Arial, Sans-Serif !important;
}

.cl-mpp-product-layout-v1 .mpp-grid ul.view-small li.mpp-box {
  .product-subheading,
  .product-subheading a,
  .product-subline,
  .product-subline a,
  h3 {
    font-family: HelveticaNeueLTStd55Roman, spoiler, Verdana, Arial, Sans-Serif !important;
  }
}

.gnav-header-formatter__hamburger--pc .text-link--style-2 {
  @media #{$cr19-large-up-landscape} {
    font-family: HelveticaNeueLTStd55Roman, spoiler, Verdana, Arial, Sans-Serif !important;
  }
}
