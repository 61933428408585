.section-foundation-finder {
  #foundation-finder-quiz {
    .foundation-finder {
      .ff-quiz {
        .picker-radio {
          .picker-handle {
            float: right;
          }
        }
        &__gallery-arrow--next,
        &__gallery-arrow--prev {
          transform: rotate(180deg);
          top: 30%;
        }
      }
    }
  }
}

.editorial-grid-formatter {
  & &-carousel-arrow {
    &.previous {
      @include slick-previous-position;
      transform: rotate(0deg);
    }
    &.next {
      @include slick-next-position;
      transform: rotate(180deg);
    }
  }
}

.gnav-block,
.gnav-submenu-layout {
  .text-link--style-2-bold {
    font-weight: bold;
  }
}
