.appt-book {
  .mobile-phone-fieldset {
    .appt-book-mobile-prefix {
      width: 20% !important;
      float: right !important;
    }
    .appt-book-mobile {
      width: 80% !important;
      float: left !important;
    }
  }
}
