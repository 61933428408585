html[lang='en-ZA'],
html[lang='he-IL'],
html[lang='cs-CZ'],
html[lang='hu-HU'] {
  #ot-sdk-btn {
    &.ot-sdk-show-settings {
      background-size: 15px !important;
      padding: 0 30px !important;
      border: 1px solid $color-light-grey !important;
      height: 30px !important;
      color: $color-cl-dark-grey !important;
      font-family: $base-bold-font-family;
      font-weight: bold;
      cursor: pointer !important;
      margin-top: 5px !important;
      &:hover {
        color: $color-cl-dark-grey !important;
      }
    }
  }
  #onetrust-close-btn-container {
    .onetrust-close-btn-handler {
      bottom: 24px !important;
      height: 12px !important;
    }
  }
}
