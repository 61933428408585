.spc_enabled {
  .enhanced-delivery-page:not([class*='enhanced_signin_page']) {
    #main.single {
      .responsive-container {
        .pg_wrapper {
          .column.left {
            #shipping-panel,
            #review-panel {
              #continue-btn {
                text-align: $rdirection;
              }
            }
            #shipping-panel {
              .delivery-form-container {
                fieldset {
                  .default_ship_container {
                    input[type='checkbox'] {
                      &:checked ~ label {
                        &::after {
                          border-width: 0 2px 2px 0;
                        }
                      }
                    }
                  }
                  .address4_container {
                    display: block;
                  }
                }
              }
              .gift-options {
                .giftwrap {
                  .sub-section {
                    margin: 0;
                    .form-item {
                      padding: 0;
                      @media #{$cr19-large-up} {
                        &.even {
                          padding-#{$ldirection}: 7px;
                        }
                      }
                    }
                    .giftwrap-switch {
                      label {
                        line-height: 1;
                        margin: 14px 0 10px !important;
                      }
                    }
                  }
                }
              }
              .shipmethod-panel {
                .content {
                  padding: 0;
                  #checkout_shipmethod {
                    .ship-method-group-label {
                      .ship-method-clickcollect {
                        margin-#{$rdirection}: 0;
                      }
                    }
                  }
                }
              }
            }
            &.checkout__content {
              .fs {
                .form-item {
                  .field[type='text'],
                  .field[type='tel'] {
                    &:required {
                      &:invalid {
                        border-color: $color-red;
                        &:empty,
                        &[value=''],
                        &:not(:checked) {
                          border-color: $color-shade-dark-grey;
                          &.error,
                          &.checkedEmpty,
                          &.touched {
                            border-color: $color-red;
                          }
                          &.touched + label {
                            &::before {
                              background: $color-white;
                              color: $color-monza-light;
                            }
                          }
                          &.touched:focus + label {
                            &::before {
                              color: $color-cl-light-black;
                            }
                          }
                          &.touched.adaptive-placeholder-mode + label {
                            &::before {
                              transform: none;
                            }
                          }
                        }
                      }
                    }
                    &.touched + label,
                    &.js-label-mode + label,
                    &:focus + label {
                      &::before {
                        @include transform(translateY(-28px) scale(0.8, 0.8));
                        color: $color-cl-light-black;
                        padding: 0 2px;
                      }
                    }
                    &:focus + label {
                      &::before {
                        @include transform(translateY(-28px) scale(0.8, 0.8));
                      }
                    }
                  }
                  .valid_marker,
                  .invalid_marker {
                    #{$rdirection}: 30px !important;
                    bottom: 8px;
                    height: 30px;
                    margin-bottom: 5px;
                    text-indent: -9999px;
                    width: 35px;
                    @media #{$cr19-large-up} {
                      #{$rdirection}: 25px;
                    }
                  }
                  .valid_marker {
                    background: url('/media/images/checkout/tick-icon_01.png') no-repeat 12px 7px;
                    background-size: 70%;
                  }
                  .invalid_marker {
                    #{$rdirection}: 30px;
                    background: url('/media/images/checkout/invalid-marker.png') no-repeat 4px 3px;
                    background-size: 100%;
                    top: auto;
                  }
                }
              }
              .edit-address-container,
              .new-address,
              .billing-address-form-fields {
                .form-item {
                  .valid_marker,
                  .invalid_marker {
                    #{$rdirection}: 15px !important;
                  }
                }
              }
            }
            .shipping-panel__title {
              color: $color-black;
            }
            .shipmethod-panel {
              margin-top: 0;
              .checkout__subtitle {
                display: none;
              }
              .checkout__panel-title {
                color: $color-black;
                padding: 0 17px 12px;
              }
            }
            .fs {
              .form-item {
                padding: 0 17px;
                @media #{$cr19-large-up} {
                  padding: 0;
                }
                input[type='text'] {
                  &[name='FEDERAL_DOC_NUMBER'] {
                    @include swap_direction(padding, 17px 15px 13px 20px);
                    background-image: linear-gradient(transparent, transparent);
                    height: 54px;
                    width: 100%;
                  }
                }
                input + label {
                  font-size: 16px;
                  font-weight: 400;
                  line-height: 1.5;
                  margin-top: calc(-36px - 1px) !important;
                }
              }
            }
            .edit-address-container,
            .new-address,
            .billing-address-form-fields {
              .field-container {
                .form-item,
                .first-name {
                  input + label {
                    margin-top: calc(-36px - 1px) !important;
                  }
                }
                .form-item {
                  .adaptive-label-mode + label {
                    &::before {
                      margin: 0 7px;
                      transform-origin: 100% 50%;
                    }
                  }
                  .field[type='text'] {
                    &:focus + label {
                      &::before {
                        margin: 0 7px;
                        transform-origin: 100% 50%;
                      }
                    }
                  }
                }
              }
              .bill-to-shipping {
                input[type='checkbox'] {
                  margin-top: 2px;
                }
              }
            }
            .billing-address-form-fields {
              &.btos {
                .field-container {
                  &.country {
                    border: 1px solid $color-shade-dark-grey;
                    padding-top: 16px;
                    .form-item.select {
                      label {
                        display: block;
                      }
                      &.country-id::after {
                        display: none;
                      }
                    }
                  }
                  .delivery_instructions {
                    display: none;
                  }
                }
              }
            }
            .email-promotion-link {
              display: none;
            }
            .personal-details {
              .field-container--grid {
                .form-item {
                  label {
                    margin-top: -37px !important;
                  }
                }
              }
            }
            .review-panel {
              .payment-method-container {
                #payment-type-cc {
                  border-bottom: 1px solid $color-lighter-gray;
                  border-top: 1px solid $color-lighter-gray;
                  .img_section {
                    width: 70%;
                  }
                }
                #payment-type-ncc {
                  border-bottom: 1px solid $color-lighter-gray;
                }
                #payment-type-cc,
                #payment-type-ncc {
                  min-height: 54px;
                  .credit_card,
                  .paypal {
                    position: relative;
                    width: 100%;
                  }
                  input[type='radio'].payment_select {
                    @include appearance(none);
                    @include border-radius(50%);
                    #{$ldirection}: 0;
                    border: 1px solid;
                    height: 20px;
                    margin: auto 16px !important;
                    position: absolute;
                    top: 16px;
                    width: 20px;
                    &:focus {
                      outline: none;
                    }
                    &:checked {
                      &::before {
                        @include border-radius(50%);
                        #{$ldirection}: 2px;
                        background: $color-black;
                        bottom: 0;
                        content: '';
                        display: block;
                        height: 14px;
                        margin: 0 auto;
                        position: absolute;
                        top: 2px;
                        width: 14px;
                      }
                    }
                  }
                  .text-label {
                    margin: 0;
                    padding: 14px 0;
                    width: 100%;
                  }
                  .img_section {
                    margin: auto 15px;
                  }
                  label {
                    @include display-flex;
                    float: $rdirection;
                    font-weight: bold;
                    justify-content: flex-end;
                    margin: 0;
                    width: 85%;
                    @media #{$cr19-large-up} {
                      padding-top: 4px;
                      width: 90%;
                    }
                  }
                }
              }
            }
            .email-and-sms-promotions {
              .newsletter__accordion {
                .icon {
                  &::before,
                  &::after {
                    @include transform(translate(50%, -50%));
                  }
                }
              }
              .fs {
                .info-message-txt {
                  display: none;
                }
              }
              .email_promotions {
                p.quick-wins-hidden {
                  margin-bottom: 40px;
                }
                input[type='checkbox'] {
                  margin-top: 7px;
                }
              }
              .form-item.pc_email_promo_container {
                padding: 0;
              }
            }
            .giftwrap-switch {
              margin: 14px 0 0;
            }
            .gift-options {
              margin: 35px 0 18px;
              .icon {
                &::before,
                &::after {
                  @include transform(translate(50%, -50%) !important);
                }
              }
              .fs {
                .giftmessage {
                  input + label {
                    margin-top: calc(-36px - 1px) !important;
                  }
                  .form-item.even {
                    padding-#{$ldirection}: 7px;
                  }
                }
              }
            }
          }
          .column.right {
            #offer-code-panel.panel {
              .content {
                #offer_code {
                  .offer-code__input {
                    input + label {
                      margin-top: -37px !important;
                      text-align: $ldirection;
                    }
                    .valid_marker,
                    .invalid_marker {
                      #{$ldirection}: auto;
                      #{$rdirection}: 0;
                    }
                  }
                }
              }
            }
            .expand-toggle {
              &::before,
              &::after {
                @include transform(translate(50%, -50%));
              }
            }
            #viewcart-shopping-bag-panel {
              .viewcart-panel__content {
                .cart-item__price {
                  float: $rdirection;
                }
                .cart-item__qty,
                .cart-item__total {
                  float: $ldirection;
                  padding-#{$ldirection}: 0;
                }
              }
              .shiping_bag_description {
                float: $ldirection;
                width: 69%;
                .desc {
                  width: 90%;
                }
              }
              &.panel {
                #gift-options-display {
                  @include swap_direction(padding, 0 14px 10px 0);
                  @media #{$cr19-large-up} {
                    @include swap_direction(padding, 0 21px 10px 13px);
                  }
                }
              }
            }
            .panel.shipping-address-panel,
            .panel.billing-address-panel,
            .panel.shipmethod-panel {
              .checkout__subtitle {
                &::before,
                &::after {
                  @include transform(translate(50%, -50%));
                }
              }
            }
          }
        }
      }
    }
    .page-footer [class*='block-template-appointment-booking-basic-responsive'] {
      margin: 0;
      padding-bottom: 100px;
    }
  }
}
