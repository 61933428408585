.spc_enabled {
  #viewcart {
    &.enhanced_cart_page,
    .enhanced_cart_page {
      @media (min-width: $medium-max) {
        .pg_wrapper {
          @include box-shadow(-10px 0 5px -11px $color-light-grey);
        }
      }
      #main.single {
        .responsive-container {
          .column {
            &.left {
              .viewcart-samples {
                .available,
                .max {
                  display: none;
                }
                .samples-carousel {
                  .samples-grid {
                    direction: ltr;
                    .slick-arrow {
                      &.slick-prev {
                        #{$rdirection}: 3px;
                        #{$ldirection}: auto;
                      }
                      &.slick-next {
                        #{$ldirection}: 0;
                        #{$rdirection}: auto;
                      }
                    }
                  }
                }
              }
              .cart-item__discount-percentage {
                display: inline-block;
              }
              .unit-discount {
                float: $ldirection;
              }
              .cart-items {
                .qty {
                  #cart {
                    .selectbox {
                      background-position: calc(50% - 16px) calc(1em + -4px), calc(50% - 11px) calc(1em + -4px), 100% 0;
                    }
                  }
                }
                .price {
                  &.column {
                    .price-wrapper {
                      float: $ldirection;
                    }
                    .cart-item__total {
                      float: $rdirection;
                    }
                  }
                }
              }
              @media (min-width: $medium-max) {
                .viewcart-panel.loading::before {
                  background-position: 65% 56%;
                }
              }
            }
            &.right {
              @media (min-width: $medium-max) {
                @include box-shadow(-1px 2px 10px $color-light-grey);
              }
              .shipmethod-panel {
                display: none;
              }
            }
          }
        }
      }
      .panel {
        header {
          &.viewcart-header {
            h2 {
              color: $color-black;
            }
          }
        }
      }
    }
    .viewcart_footer .menu li a.icon-lock {
      &::before {
        display: none;
      }
      &::after {
        content: '\f023';
        font-family: $base-fontawesome-font-family;
        font-size: 16px;
        vertical-align: middle;
      }
    }
  }
}
