.ui-autocomplete {
  max-height: 150px;
  overflow-y: auto;
  overflow-x: hidden;
}

* html .ui-autocomplete {
  height: 150px;
}

.custom-input {
  //I needed to use important since there are lots of dependencies
  //and there is id for width attribute.
  width: 90% !important;
}

.custom-icon {
  height: 54px;
  border-bottom-right-radius: 0px;
  border-top-right-radius: 0px;
  margin: 0;
  background: $color-white;
  //I needed to use important because in Jquery.ui lib
  //button html tag had used.
  width: 10% !important;
  margin-top: -8px;
  border-color: #999;
  border-width: 1px;
  border-style: solid none solid solid;
}

.icon-search {
  position: absolute;
  right: 35px;
  top: 795px;
  color: $color-dark-grey;
  font-size: 17px;
}

.custom-search-icon {
  background-image: url('/media/images/global/search_blue.png');
  background-size: auto 50%;
  background-repeat: no-repeat;
  background-position: right center;
}

.custom-error {
  border-color: $color-red;
}

.custom-error::placeholder {
  color: $color-mona-lisa;
  opacity: 1;
}

.custom-error:-ms-input-placeholder {
  color: $color-mona-lisa;
  opacity: 1;
}
