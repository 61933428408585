@import '../theme-bootstrap';

.elc-grid-container .elc-product-name-section .elc-clickable-wrapper {
  .elc-product-original-price.elc-price-formatted,
  .elc-product-prices-wrapper .elc-product-price-range-row-wrapper .elc-product-price-range,
  .elc-product-prices-clickable-wrapper
    .elc-product-price-range-row-wrapper
    .elc-product-price-range {
    font-size: 22px;
    @media #{$cr19-medium-up} {
      font-size: 24px;
    }
  }
}

.sd-product-grid {
  .elc-product-carousel-container {
    .elc-product-brief {
      &:hover {
        .elc-product-name-section {
          @media #{$cr19-large-up} {
            display: none;
          }
        }
        .elc-product-details-section {
          @media #{$cr19-large-up} {
            display: flex;
            flex-flow: column;
          }
        }
      }
      .elc-product-name-section {
        .elc-product-display-name-wrapper + .elc-product-prices-clickable-wrapper {
          width: 100%;
        }
      }
      .elc-product-details-section {
        display: flex;
        flex-wrap: wrap;
        @media #{$cr19-large-up} {
          display: none;
        }
        .elc-clickable-wrapper {
          flex: 50%;
          padding-top: 15px;
          order: 3;
          text-align: right;
          width: auto;
          .elc-product-rating-wrapper {
            .elc-stars-simplified::before {
              filter: brightness(1);
            }
          }
        }
        .elc-size-picker-container,
        .elc-shades-dropdown-component {
          flex: 100%;
          order: 1;
        }
        .elc-product-full-cta-wrapper {
          flex: 50%;
          order: 2;
          .elc-add-to-bag-button {
            &:after {
              padding: 3px 19px;
            }
          }
        }
      }
      .elc-shades-dropdown-component {
        .elc-product-shade-picker-dropdown {
          width: 100%;
          .elc-dropdown-icon-container {
            order: 3;
          }
          .elc-dropdown-readonly-input {
            order: 2;
          }
          .elc-dropdown-arrow {
            border-right: none;
            order: 1;
          }
        }
      }
      .elc-size-picker-container {
        .slick-track {
          justify-content: end;
        }
      }
      .elc-product-full-cta-wrapper {
        .elc-add-to-bag-button {
          text-align: left;
        }
        .elc-button.elc-product-notify-me-button {
          text-align: right;
        }
      }
    }
  }
}
