.device-mobile {
  [class*='block-template-search-mobile-v'] {
    float: left;
  }
  [class*='block-template-gnav-cart-v'] {
    float: right;
  }
  .popup-offer-v1 {
    .outer-wrap {
      &.signup {
        height: 550px;
        overflow: hidden;
        width: 320px;
        top: 50%;
        #{$rdirection}: 50%;
        transform: translate(-50%, -50%);
        padding-top: 0;
        .subheading {
          margin-bottom: 10px;
        }
      }
    }
    .popup-offer-close {
      background: transparent url('/media/export/cms/images/icon-close.png') no-repeat scroll 0 0/15px 15px;
      margin: 10px;
    }
    .form-submit-wrap {
      height: 60px;
      flex-direction: row;
      display: flex;
      align-items: center;
      justify-content: center;
      .form-text {
        width: 70%;
        padding: 2px 13px;
      }
      input.button[type='submit'] {
        font-size: 20px;
        width: auto;
        margin-#{$ldirection}: 3px;
        padding: 0 1em;
        height: 40px;
      }
    }
    .popup-offer-footer-msg {
      text-align: center;
    }
  }
  .gnav-block {
    &__courtesy,
    &__courtesy-utilities {
      flex-direction: row-reverse;
    }
    &__main-navigation-link {
      svg.svg-icon {
        transform: rotate(180deg);
      }
      &-back {
        svg.svg-icon {
          transform: none;
        }
      }
    }
  }
}

.mobile_mpp {
  .mpp-filter {
    .menu-container.mpp-filter-content.depth-2.filter-by-skin-concern {
      position: relative;
      top: -100px;
    }
    .menu-container.mpp-filter-content.depth-2.filter-by-skin-type {
      position: relative;
      top: -150px;
    }
  }
  .unit-price {
    color: #000000;
  }
}

.device-mobile {
  .product.spp-product {
    .mpp_product {
      .product_size {
        font-size: 18px;
        margin-right: 15px;
      }
      p.price {
        color: gray;
      }
      p.price.unit-price {
        color: #000000;
      }
    }
  }
}

.power-couples-formatter-rows {
  #acne-breakout h3 span:first-child {
    background-position: -2px 4px;
    height: 40px;
  }
  #lines-wrinkles h3 span:first-child {
    background-position: 0 -74px;
    height: 40px;
  }
  #dryness h3 span:first-child {
    background-position: -2px -155px;
    height: 50px;
  }
  #enlarged-pores h3 span:first-child {
    background-position: -2px -233px;
    height: 53px;
  }
  #redness h3 span:first-child {
    background-position: -2px -312px;
    height: 47px;
  }
  #dark-spots h3 span:first-child {
    background-position: -2px -392px;
    height: 46px;
  }
}

.accordionItem.three-step-shopbystep-mobile-v1 {
  h3.product-name {
    max-height: 92px;
  }
}

.navigation_container {
  ul {
    li {
      a {
        text-align: right;
      }
    }
  }
  li.parent {
    span.icon {
      left: 0 !important;
      background: url('/media/export/cms/mobile/prev-next-arrows.png') no-repeat scroll 0 5px rgba(0, 0, 0, 0);
    }
  }
}

.navigation_container .field-mobile-menu li ul.menu {
  right: auto;
  left: 100%;
}

.device-mobile #search #search-results .results .result .badge {
  .badge-inner.badge_data_1,
  .badge-inner.badge_data_30 {
    display: none;
  }
  .badge-inner.badge_data_1.new,
  .badge-inner.badge_data_30.bestseller {
    display: inline-block;
  }
}
/* Logged in menu items. Hidden for Drupal/PG anonymous users */
.logged-in .parent-כניסה-למערכת,
.elc-user-state-logged-in .parent-כניסה-למערכת {
  display: none;
}

.elc-user-state-anonymous .parent-יציאה,
.elc-user-state-anonymous .parent-my-purchases,
.elc-user-state-anonymous .parent-reorder-from-past-purchases {
  display: none;
}

.elc-user-state-anonymous .parent-כניסה-למערכת {
  display: inline;
}

.elc-user-state-logged-in .parent-יציאה {
  display: inline;
}

li.cs-menu-mobile-v1 h2.cs-slide-menu-heading {
  a {
    padding-right: 40px;
    span.icon-next {
      background-position: 7px 8px;
      margin-right: 10px;
    }
  }
}

.pcg-product-mobile-v1 {
  .product-info {
    .unit-price {
      color: #000000;
    }
    .online-price-hover-content {
      display: none;
      background: #fff;
      border: 1px solid #000;
      line-height: 1.3;
      margin-top: -90px;
      padding: 5px;
      position: absolute;
    }
  }
}

.three-step-row-mobile-v1 {
  .row-products {
    .product {
      .unit-price {
        color: #000000;
      }
      .online-price-hover-content {
        display: none;
        background: #fff;
        border: 1px solid #000;
        margin-top: -81px;
        padding: 2%;
        position: absolute;
        width: 67%;
      }
    }
  }
}

.power-couples-row-mobile-v1 {
  .product {
    .unit-price {
      color: #000000;
    }
    .online-price-hover-content {
      display: none;
      background: #fff;
      border: 1px solid #000;
      margin-top: -62px;
      padding: 0.5%;
      position: absolute;
    }
  }
}

#registration.device-mobile .registration_content .skin_concern input[type='radio'] {
  display: inline;
  float: none;
}

.my-account-favorites-mobile-v1 .tabs .no-favorites .no-favorites-text p {
  font-size: 22px;
}

.store-locator-mobile-v1 .sbSelector {
  text-indent: 14px;
}

.mpp-product .sbSelector {
  text-indent: 14px;
}

.section-customer-care {
  .cs-formatter-menu-mobile-v1 {
    ul.menu {
      .depth-1 {
        margin-right: 0;
      }
    }
  }
}

li.cs-menu-mobile-v1.parent h2.back a span.icon {
  background-position: -18px 1px;
  margin: 0 10px 0 3px;
}
/* just overwriting position to default value as it causes styling issues with RTL */
.contextual-links-region {
  position: static;
}

.block.block-template-site-logo-responsive-v1 div {
  height: auto;
}

.device-mobile #footer .footer-bottom .field-mobile-menu ul.menu > li.item-3,
.device-mobile #footer .footer-bottom .field-mobile-menu ul.menu > li.item-5,
.device-mobile #footer .footer-bottom .field-mobile-menu ul.menu > li.item-4 {
  border-right: 1px solid #231f20;
  padding: 0 10px;
}

.spp-product {
  .shaded {
    .skin_types {
      .sbSelector {
        margin-right: 35px;
      }
    }
  }
}

.device-mobile {
  #foreground-node.overlay-container.email_waitlist_overlay_container {
    .close-container {
      position: absolute !important;
    }
  }
}

.not_shaded_sized {
  .spp_product_status {
    .coming_soon_stock_msg a.btn-comingsoon {
      line-height: normal;
      height: 77px;
      padding: 12px;
    }
  }
}

.device-mobile {
  .footer-bottom {
    .field-mobile-menu {
      ul.menu {
        a.toggle-mobile {
          height: 15px !important;
        }
      }
    }
  }
}

.product-info {
  .online-price-hover-content {
    display: none !important;
  }
}

.block-template-search-mobile-v2 {
  float: right;
  position: absolute !important;
  right: 48px !important;
  display: inline-block !important;
}

.btn_navigation {
  position: absolute;
  right: 0;
}

.block-template-gnav-utility-button-mobile-v1 {
  position: absolute;
  left: 48px;
  .gnav-utility-button-v1 {
    border-left: none !important;
  }
}

.block-template-gnav-cart-v1 {
  position: absolute;
  float: left;
  left: 0px;
}

.appt-book {
  #booking-step3 {
    .book-appt-container {
      .registration__terms.error {
        color: #ef6ea8;
        a {
          color: #ef6ea8;
        }
      }
      .registration__email-list,
      .registration__sms-list,
      .registration__terms {
        input {
          float: right;
          margin-left: 5px;
        }
      }
      .registration__sms-list {
        display: block;
      }
    }
    .confirm-container {
      .start-over {
        width: 33%;
      }
    }
  }
  .appt-book-page-header {
    .appt-book-page-header-content h3 {
      margin: 0 10% 0 0;
    }
  }
  #services {
    .services-skincare,
    .services-makeup {
      margin-bottom: 0 !important;
      .section-header__inner {
        color: #46ab8c;
        font-size: 30px;
      }
      .service-head {
        .service-title {
          font-size: 22px;
        }
      }
      .service-details {
        text-align: right;
      }
    }
  }
  .appointment-select {
    .appointments-container {
      .booking-step {
        text-align: right !important;
        h3,
        h4 {
          text-align: right;
        }
      }
    }
  }
  #booking-step1 {
    .selects-container {
      .counter-select-container.select-container,
      .artist-select-container.select-container {
        padding-left: 20%;
        padding-right: 0;
      }
    }
    .service-head {
      margin-top: 8px;
    }
  }
  .confirmation {
    .confirmation-container {
      .your-lessons,
      .location,
      .date-time,
      .artist {
        h4,
        span {
          text-align: right;
        }
        a.location-direction-links {
          float: right;
        }
      }
      .appt-date-cal {
        background-position: 0em 0.1em;
        width: 50%;
        .add-cal-title,
        .add-cal-desc {
          display: none;
        }
      }
    }
  }
  .appointment-container {
    .your-lessons,
    .appt-date {
      p {
        display: inline-block;
      }
    }
  }
}

.appt-book-overlay.appt-book-services-overlay {
  .overlay-content-container {
    height: auto !important;
  }
}

.appt-book-overlay.appt-book-book-conflict-overlay {
  .overlay-content-container {
    top: 50% !important;
    width: 85%;
    right: 23px;
    .overlay-close {
      left: 10px;
      width: auto;
      overflow: hidden;
      text-indent: 100%;
    }
    .overlay-content {
      text-align: right !important;
      span p {
        text-align: right !important;
      }
    }
  }
}

.appt-book-overlay.my-appointments-overlay {
  .overlay-close {
    left: 10px;
  }
  .overlay-content {
    text-align: right !important;
    span {
      text-align: right !important;
      p {
        display: inline;
        margin: 0;
      }
    }
  }
}

.location_info {
  .info_content {
    padding-bottom: 35px;
    width: 180px;
  }
}

.doors_results {
  .store-locator_book-appointment {
    .icon {
      height: 26px !important;
      width: 21px !important;
      margin-top: 0 !important;
    }
  }
}

.device-mobile .full_width .how_to_use {
  padding: 0 15px 10px;
  border-bottom: solid 1px #ccc;
}

#signin {
  .navigation_container {
    height: 1728px !important;
  }
}

body {
  &.section-foundation-finder {
    #foundation-finder-quiz {
      .foundation-finder {
        &-results {
          .refresh-section-wrapper {
            padding: 20px;
          }
        }
        .ff-quiz {
          &__carousel {
            .slick-track {
              width: max-content !important;
              display: flex;
            }
          }
          &__slide {
            padding: 12px;
          }
        }
      }
    }
  }
}

.product-list {
  .shaded {
    .sbSelector {
      color: $color-black;
    }
  }
}
