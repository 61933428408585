@import '../theme-bootstrap';

.elc-product-full {
  .elc-product-price-row-wrapper {
    .elc-product-original-price.elc-price-formatted {
      @include text-title--med--no-xl;
    }
  }
  .elc-sidebar-button-section .elc-product-shades-view-button {
    @media #{$cr19-medium-up} {
      width: 300px;
      padding-#{$rdirection}: 0;
      span div:first-child {
        width: 80%;
      }
    }
  }
}
