//Review
.DisplayContent_BVRR {
  font-family: 'HelveticaNeueLTStd45Light', 'Helvetica Neue', Helvetica, Arial, Sans-Serif;
  float: right;
  width: 100%;
  color: #000000;
  border-bottom: 1px solid #f5f5f5;
  .SubmissionPopupContainer_BV {
    max-width: 1008px;
    margin: 0 auto;
    padding-top: 20px;
    padding-bottom: 20px;
    background: url(http://clinique.ugc.bazaarvoice.com/static/3813/backgroundVerticalDivider.gif) repeat-y 26.75% top;
    .ReviewDisplay_BVRR {
      float: left;
      width: 30%;
      padding-top: 47px;
      padding-right: 6%;
      .UserNickName_BVRR {
        margin-bottom: 5px;
        font-size: 22px;
        line-height: 24px;
        word-wrap: break-word;
      }
      li.BVDI_BAItemTop50Contributor.BVDI_BAItemContributor {
        margin: 0px 0px;
        a.BVDILink {
          background-position: 80px -38px;
          span.BVDILinkSpan {
            margin-right: 21px;
          }
        }
      }
      .ContextdataContainer_BVRR {
        line-height: 1.5em;
        .DatavaluePrefixBold_BVRR {
          padding-left: 3px;
          float: right;
          text-transform: capitalize;
        }
        .DatavaluePrefix_BVRR {
          font-weight: bold;
          text-transform: capitalize;
        }
      }
      .SeeFullProfile_BVRR {
        margin-top: 10px;
        line-height: 1.5em;
        width: 100%;
        float: right;
      }
    }
    .ReviewDisplayheader_BVRR {
      float: left;
      width: 70%;
      margin-bottom: 20px;
      overflow: hidden;
      .ReviewDateContainer_BVRR {
        float: left;
        line-height: 26px;
      }
      .ReviewRatingsContainer_BVRR {
        margin-bottom: 20px;
        float: right;
      }
    }
    .ReviewTitleContainer_BVRR {
      float: right;
      width: 100%;
      font-size: 22px;
    }
    .ReviewDisplayContentPrimary_BVRR {
      font-size: 15px;
      float: right;
      width: 70%;
      line-height: 1.5em;
      .ReviewTextContainer_BVRR,
      .ReviewDisplayRecommended_BVRR {
        width: 100%;
        margin-bottom: 15px;
        float: right;
        .ReviewDisplayText_BVRR {
          margin: 10px 0px;
          word-wrap: break-word;
        }
      }
    }
    .ReviewDisplayContentSample_BVRR {
      width: 70%;
      float: right;
      margin-bottom: 20px;
      font-size: 15px;
    }
    .BVRRMobileUserReviewCustomBody {
      display: none;
    }
    .ReviewDisplayContentFooter_BVRR {
      border-top: 1px solid #dbdbdb;
      width: 70%;
      float: right;
      padding-top: 15px;
      .ReviewDisplayHelpful_BVRR {
        float: right;
        .ReviewDisplayQuestion_BVRR {
          float: right;
          .Question_BVRR {
            font-size: 13px;
            padding-left: 20px;
            float: right;
            line-height: 23px;
          }
          .ReviewDisplayFlag {
            float: right;
            background-image: url(http://clinique.ugc.bazaarvoice.com/static/3813/iconReport.gif);
            height: 20px;
            width: 20px;
            cursor: pointer;
          }
          .YesNofeedbackContainer {
            float: right;
            margin-left: 20px;
            span.YesNofeedbackCount {
              float: right;
            }
            #Answer_Yes,
            #Answer_No {
              float: right;
              background-color: #bebebe;
              width: auto;
              cursor: pointer;
              padding: 2px 10px;
              color: #ffffff;
              font-family: Arial, Sans-Serif;
              font-weight: bold;
              text-align: center;
              letter-spacing: 1px;
              line-height: 19px;
              font-size: 12px;
              margin-left: 10px;
              text-transform: uppercase;
            }
            #Answer_No:hover,
            #Answer_Yes:hover {
              background-color: #808080;
            }
          }
        }
      }
      .ReviewSocialIcons {
        float: left;
        a {
          margin-right: 10px;
          float: left;
        }
      }
    }
  }
}
//ratings
.Ratings_BVRR {
  float: right;
  width: 100%;
  padding: 0 25px 0 0;
  font-family: 'HelveticaNeueLTStd45Light', 'Helvetica Neue', Helvetica, Arial, Sans-Serif;
  background-image: -webkit-gradient(linear, left bottom, left top, color-stop(0.18, #f7f7f7), color-stop(0.59, white));
  border-bottom: 1px solid #dbdbdb;
  .ratingsheader_BVRR {
    max-width: 1008px;
    margin: 0 auto;
    border-bottom: 1px solid #dbdbdb;
    .ratings_reviews_BVRR {
      display: block;
      margin: 0;
      padding: 10px 0;
      line-height: 41px;
      color: #333333;
      font-size: 22px;
      letter-spacing: -0.1mm;
      font-weight: normal;
    }
  }
  .ratingsummary_BVRR {
    max-width: 1008px;
    margin: 0 auto;
    float: right;
    .firstreview_BVRR {
      padding: 12px 0 0 30px;
      letter-spacing: -0.1mm;
      color: #666;
      font-size: 20px;
    }
    .firstreviewRatings_reviews_BVRR {
      color: #333333;
      letter-spacing: -0.1mm;
      font-size: 22px;
    }
    .starscontainer_BVRR {
      float: right;
      width: 206px;
      margin: 21px 0 20px;
      #totalreviewscount_BVRR {
        color: #5dba98;
        padding-left: 5px;
        float: right;
        margin-top: 5px;
      }
    }
    .recommendation_BVRR {
      line-height: 1.5em;
      color: #000000;
      width: 346px;
      margin: 22px 0 16px;
      clear: none;
      float: right;
      margin-left: 25px;
      .percentagerecommended_BVRR {
        font-size: 22px;
      }
      .product_friend_recommend_BVRR {
        font-size: 13px;
        line-height: 30px;
      }
    }
    .filter_BVRR {
      width: 161px;
      float: right;
      a {
        border-right: 1px solid #dbdbdb;
        border-left: 1px solid #dbdbdb;
        display: block;
        padding: 19px 15px;
        text-align: center;
        span {
          display: inline-block;
          background: #5dba98;
          width: auto;
          padding: 6px 15px;
          cursor: pointer;
          color: #ffffff;
          font-family: Arial, Sans-Serif;
          font-size: 15px;
          font-weight: bold;
          text-transform: uppercase;
          line-height: 18px;
        }
        span:hover {
          background-color: #579077;
        }
      }
    }
    .review_BVRR {
      width: auto;
      height: 50px;
      float: right;
      margin-top: 5px;
      a {
        display: block;
        text-align: center;
        span#BVRR_WriteAReview {
          display: inline-block;
          background: #5dba98;
          padding: 13px 15px;
          width: auto;
          cursor: pointer;
          color: #ffffff;
          font-family: Arial, Sans-Serif;
          font-size: 15px;
          font-weight: bold;
          text-transform: uppercase;
          line-height: 18px;
        }
        span:hover {
          background-color: #579077;
        }
      }
    }
    .review_BVRR#reviews_BVRR_WriteReview {
      padding: 19px 30px;
      height: auto;
      margin-top: 0px;
      a {
        span#BVRR_WriteAReview {
          padding: 6px 15px;
        }
      }
    }
  }
  .ratingsummary_BVRR#summary_BVRR_ratings {
    margin-right: 11%;
  }
}
//write a review
.writeareview_BVRR {
  max-width: 1008px;
  margin: 0 auto;
  .writereviewcontainer_BVRR {
    font-family: 'HelveticaNeueLTStd45Light', 'Helvetica Neue', Helvetica, Arial, Sans-Serif;
    line-height: 1.5em;
    color: #000000;
    font-size: 15px;
    .pageheaderreview_BVRR {
      margin-bottom: 20px;
      padding-top: 40px;
      span {
        color: #333333;
        font-size: 22px;
        letter-spacing: -0.1mm;
        display: block;
      }
    }
    .form_BVRR {
      .formbody_BVRR {
        .required_fields_BVRR {
          font-size: 13px;
          margin: 40px 0 15px;
        }
        .formoverall_BVRR {
          .overallratingfrom_BVRR {
            margin-bottom: 10px;
            float: right;
            width: 100%;
            span {
              float: right;
            }
            #ratingvalue {
              height: 24px;
              display: block;
              padding: 4px 10px 0px 3px;
              font-size: 13px;
            }
          }
          .recommendationfrom_BVRR {
            margin-bottom: 15px;
            clear: both;
            float: right;
            width: 100%;
            .wouldyourecommendthis_BVRR {
              margin-left: 10px;
              float: right;
              width: 200px;
            }
            span.containerspanrecommend_BVRR {
              float: right;
              span.yesrecommend_BVRR {
                margin-left: 15px;
                float: right;
                input {
                  margin-left: 5px;
                }
              }
            }
          }
        }
        .formreviewcontainer_BVRR {
          float: right;
          .sampleratingfrom_BVRR {
            margin-bottom: 10px;
            float: right;
            width: 100%;
            .receiveproductsample_BVRR {
              margin-left: 10px;
              float: right;
              width: 200px;
            }
            span.containerspanrecommend_BVRR {
              float: right;
              span.yesrecommend_BVRR {
                margin-left: 15px;
                float: right;
                input {
                  margin-left: 5px;
                }
              }
            }
          }
          .reviewtitlefrom_BVRR {
            float: right;
            width: 100%;
            margin-top: 40px;
            margin-bottom: 10px;
            .titleinput_BVRR {
              width: 577px;
              border: 1px solid #dbdbdb;
              background-color: #f1f4f5;
              font-size: 15px;
              color: #111;
              line-height: 35px;
              height: 35px;
              padding: 0 13px;
              float: right;
            }
            .BVME_Popin {
              position: absolute;
              right: 745px;
              margin: 10px 10px 0 0;
              .BVME_PopinLink a {
                text-indent: 999em;
              }
              .BVME_PopinContent .BVME_TipBoxClose {
                text-indent: 999em;
                float: left;
              }
              .BVME_PopinContent .BVBody ul {
                margin: 5px 0;
                margin-right: 1.5em;
              }
              .BVME_PopinContent .BVBody ul li {
                list-style: disc;
              }
            }
            .titleofreview {
              display: block;
              padding-top: 4px;
              text-align: right;
            }
          }
          .fullreviewtitlefrom_BVRR {
            float: right;
            .fullreviewinput_BVRR {
              width: 610px;
              border: 1px solid #dbdbdb;
              background-color: #f1f4f5;
              font-size: 15px;
              color: #111;
              line-height: 35px;
              height: 175px;
              padding: 0 13px;
            }
            .fulltitleofreview {
              display: block;
              padding-top: 4px;
              text-align: right;
            }
          }
        }
        .yourdetails_BVRR {
          margin-top: 10px;
          float: right;
          .yourdetailsfield_BVRR {
            margin-bottom: 10px;
            .customerdetails_BVRR {
              display: block;
              padding-top: 4px;
              text-align: right;
            }
            .customerdetailsinput_BVRR {
              width: 262px;
              border: 1px solid #dbdbdb;
              background-color: #f1f4f5;
              font-size: 15px;
              color: #111;
              line-height: 35px;
              height: 35px;
              padding: 0 13px;
            }
            .customerdetailselect_BVRR {
              width: 262px;
              border: 1px solid #dbdbdb;
              cursor: pointer;
              font-size: 15px;
              color: #111;
              line-height: 35px;
              height: 35px;
              padding: 0 13px 0 0;
            }
          }
        }
      }
      .detailsfooter_BVRR {
        width: 100%;
        float: left;
        padding: 25px 0 10px;
        margin-bottom: 10px;
        .buttonsid_BVRR {
          float: right;
          margin-bottom: 10px;
          .button_BVRR {
            display: inline-block;
            background: #5dba98;
            width: auto;
            padding: 6px 15px;
            overflow: visible;
            vertical-align: top;
            cursor: pointer;
            color: #ffffff;
            font-weight: bold;
            text-transform: uppercase;
            text-align: center;
            line-height: 18px;
            border: none;
          }
          .button_BVRR:hover,
          .cancelbutton_BVRR:hover {
            background: #929292;
          }
          .cancelbutton_BVRR {
            display: inline-block;
            background: #bebebe;
            width: auto;
            padding: 6px 15px;
            overflow: visible;
            vertical-align: top;
            cursor: pointer;
            color: #ffffff;
            font-weight: bold;
            text-transform: uppercase;
            text-align: center;
            line-height: 18px;
            border: none;
          }
        }
        .footerlinks_BVRR {
          float: left;
          width: 300px;
          margin-bottom: 10px;
          a {
            float: left;
            margin-right: 10px;
            color: #333333;
          }
          a:hover {
            text-decoration: underline;
          }
        }
      }
    }
  }
  .Errors_BVRR {
    margin-bottom: 20px;
    color: #ef6ea8;
    .errorsdisplayed_BVRR {
      ul {
        list-style: disc;
        margin-right: 1.5em;
      }
    }
  }
}
//preview my review
.previewmyreviewcontainer_BVRR {
  max-width: 1008px;
  margin: 0 auto;
  .Previewmyreview-BVRR {
    font-family: 'HelveticaNeueLTStd45Light', 'Helvetica Neue', Helvetica, Arial, Sans-Serif;
    line-height: 1.5em;
    float: right;
    color: #000000;
    font-size: 15px;
    .Previewmyreview-header_BVRR {
      margin-bottom: 20px;
      padding-top: 40px;
      .Previewmyreviewheader_BVRR {
        color: #333333;
        font-size: 22px;
        display: block;
        letter-spacing: -0.1mm;
      }
      .checkyourreview_BVRR {
        margin: 20px 0px 20px 0px;
        display: block;
      }
    }
    .Previewmyreviewfooter_BVRR {
      border-top: 1px solid #dbdbdb;
      padding: 20px 0px 10px 0px;
      margin: 40px 0px 10px 0px;
      .submittingreview_BVRR {
        display: block;
        margin-bottom: 20px;
        a {
          text-decoration: none;
          color: #5dba98;
        }
      }
      .buttonsid_BVRR {
        float: right;
        margin-bottom: 10px;
        .button_BVRR {
          display: inline-block;
          background: #5dba98;
          width: auto;
          padding: 6px 15px;
          overflow: visible;
          vertical-align: top;
          cursor: pointer;
          color: #ffffff;
          font-weight: bold;
          text-transform: uppercase;
          text-align: center;
          line-height: 18px;
          border: none;
          margin-left: 10px;
        }
        .button_BVRR:hover,
        .cancelbutton_BVRR:hover {
          background: #929292;
        }
        .cancelbutton_BVRR {
          display: inline-block;
          background: #bebebe;
          width: auto;
          padding: 6px 15px;
          overflow: visible;
          vertical-align: top;
          cursor: pointer;
          color: #ffffff;
          font-weight: bold;
          text-transform: uppercase;
          text-align: center;
          line-height: 18px;
          border: none;
          margin-left: 10px;
        }
      }
    }
  }
}
//Thank you page//
.Thankyoucontainer_BVRR {
  font-size: 15px;
  max-width: 1008px;
  margin: 0 auto;
  color: #000000;
  font-family: 'HelveticaNeueLTStd45Light', 'Helvetica Neue', Helvetica, Arial, Sans-Serif;
  line-height: 1.5em;
  .Thankyoupage_BVRR {
    float: right;
    .Thankyouheader_BVRR {
      padding-top: 40px;
      margin-bottom: 20px;
      span {
        display: block;
        color: #333333;
        font-size: 22px;
      }
    }
    .Thankyoubody_BVRR {
      .reviewappear_BVRR {
        display: block;
        margin-bottom: 20px;
      }
      .socialiconsthankyou_BVRR {
        margin: 20px 0;
        span,
        a {
          float: right;
          margin-left: 5px;
        }
      }
    }
  }
}
//filter//
.filtercontent_BVRR {
  float: right;
  width: 100%;
  border-bottom: 1px solid #dbdbdb;
  .filtercontainer_BVRR {
    font-size: 15px;
    max-width: 1008px;
    margin: 0 auto;
    color: #000000;
    font-family: 'HelveticaNeueLTStd45Light', 'Helvetica Neue', Helvetica, Arial, Sans-Serif;
    line-height: 1.5em;
    height: autopx;
    padding: 20px 20px 20px 20px;
    .skintypecontainer_BVRR {
      width: 240px;
      float: right;
      .skintypeheading_BVRR {
        font-size: 22px;
        text-transform: capitalize;
        line-height: 26px;
        display: inline-block;
        cursor: default;
        text-decoration: none;
        margin-bottom: 10px;
        margin-right: 20px;
      }
      .skintypecheckbox_BVRR {
        list-style-type: none;
        li {
          font-size: 13px;
          padding-right: 10px;
          line-height: 24px;
          .typeofskin_BVRR {
            padding-right: 10px;
            float: right;
          }
          .usersnumber_BVRR {
            padding-right: 3px;
          }
        }
        .skintypecheckboxli {
          vertical-align: middle;
          cursor: pointer;
          margin-right: -12px;
          float: right;
          margin-top: 7px;
        }
        .skintypecheckboxli:hover {
          background: #90ccba;
        }
      }
    }
    .agecontainer_BVRR {
      width: 180px;
      float: right;
      .agecontainerheading_BVRR {
        font-size: 22px;
        text-transform: capitalize;
        line-height: 26px;
        display: inline-block;
        cursor: default;
        text-decoration: none;
        margin-bottom: 10px;
        margin-right: 20px;
      }
      .skintypecheckbox_BVRR {
        list-style-type: none;
        li {
          font-size: 13px;
          padding-right: 10px;
          line-height: 24px;
          .typeofskin_BVRR {
            padding-right: 10px;
            float: right;
          }
          .usersnumber_BVRR {
            padding-right: 3px;
          }
        }
        .skintypecheckboxli {
          vertical-align: middle;
          cursor: pointer;
          margin-right: -12px;
          float: right;
          margin-top: 7px;
        }
        .skintypecheckboxli:hover {
          background: #90ccba;
        }
      }
    }
    .concerncontainer_BVRR {
      width: 380px;
      float: right;
      .concerncontainerheading_BVRR {
        font-size: 22px;
        text-transform: capitalize;
        line-height: 26px;
        display: inline-block;
        cursor: default;
        text-decoration: none;
        margin-bottom: 10px;
        margin-right: 20px;
      }
      .skintypecheckbox_BVRR {
        list-style-type: none;
        li {
          font-size: 13px;
          padding-right: 10px;
          line-height: 24px;
          width: 170px;
          float: right;
          .typeofskin_BVRR {
            padding-right: 10px;
            float: right;
          }
          .usersnumber_BVRR {
            padding-right: 3px;
          }
        }
        .skintypecheckboxli {
          vertical-align: middle;
          cursor: pointer;
          margin-right: -12px;
          float: right;
          margin-top: 7px;
        }
        .skintypecheckboxli:hover {
          background: #90ccba;
        }
      }
    }
    .closebutton_BVRR {
      float: left;
      background-image: url(http://clinique.ugc.bazaarvoice.com/static/3813/iconText.gif);
      background-repeat: no-repeat;
      background-position: 0px -280px;
      padding: 0;
      display: block;
      text-align: left;
      text-indent: -999em;
      text-decoration: none;
      zoom: 1;
      overflow: hidden;
      width: 22px;
      height: 22px;
      cursor: pointer;
    }
  }
}
//sortby//
.sortbycontainer_BVRR {
  float: right;
  width: 100%;
  .sortbycontainercontent_BVRR {
    font-size: 13px;
    padding: 20px 0 30px;
    max-width: 1008px;
    margin: 0 auto;
    color: #000000;
    font-family: 'HelveticaNeueLTStd45Light', 'Helvetica Neue', Helvetica, Arial, Sans-Serif;
    line-height: 1.5em;
    .sortbyheading_BVRR {
      padding: 0px 0px 0px 5px;
      float: right;
    }
    .sortbycriteria_BVRR {
      float: right;
      a {
        color: #999999;
        margin-left: 10px;
        float: right;
      }
      a:hover {
        text-decoration: underline;
        color: #333333;
      }
    }
  }
}
//loading image//
.progressNode {
  margin-top: 1%;
  margin-right: 15%;
  float: right;
}
//pagination//
#BVRR_PaginationContainer {
  float: right;
  width: 100%;
  padding: 20px 0;
  font-size: 15px;
  line-height: 1.5em;
  text-transform: uppercase;
  font-family: 'HelveticaNeueLTStd45Light', 'Helvetica Neue', Helvetica, Arial, Sans-Serif;
  #BVRRPagination {
    text-align: center;
    span {
      span.current,
      span.disabled {
        color: #000000;
        padding: 5px 10px;
      }
      a.BVRRPages {
        padding: 5px 10px;
      }
    }
  }
}

.pc_spp_view {
  .page-spp.bv-widgets {
    border-bottom: none;
  }
}

.bv_hide {
  display: none;
}

@media only screen and (min-width: 320px) and (max-width: 767px) {
  .spp_reviews .Ratings_BVRR {
    padding: 0 15px 0 0;
    border-bottom: none;
    .ratingsheader_BVRR {
      #BVDITitle {
        line-height: 24px;
      }
    }
    .ratingsummary_BVRR#summary_BVRR_ratings {
      margin-right: 0;
      .recommendation_BVRR {
        width: 100%;
        margin: 0;
        span {
          line-height: 25px;
        }
      }
      .filter_BVRR {
        display: none;
      }
      #reviews_BVRR_WriteReview {
        margin: 20px 0;
        width: 100%;
        padding: 0;
        a span {
          width: 100%;
        }
      }
    }
  }
  .spp_reviews #BVRRSortContainer {
    padding: 20px 15px 30px 15px;
    .sortbycontainercontent_BVRR {
      padding: 0;
      .sortbyheading_BVRR {
        width: 100%;
      }
    }
  }
  .spp_reviews .DisplayContent_BVRR {
    .SubmissionPopupContainer_BV {
      padding: 20px 15px 0px 15px;
      background: none;
      .ReviewDisplay_BVRR {
        display: none;
      }
      .ReviewDisplayheader_BVRR {
        width: auto;
        float: right;
      }
      .ReviewDisplayContentPrimary_BVRR {
        width: auto;
        .ReviewTextContainer_BVRR {
          float: none;
        }
      }
      .ReviewDisplayContentSample_BVRR {
        width: auto;
      }
      .ReviewDisplayContentFooter_BVRR {
        padding: 0;
        margin-bottom: 23px;
        border-top: none;
        .ReviewDisplayHelpful_BVRR {
          .Question_BVRR {
            margin-bottom: 10px;
          }
          .YesNofeedbackContainer {
            margin-left: 10px;
            .YesNofeedbackCount {
              font-size: 22px;
              line-height: 24px;
            }
          }
          .ReviewDisplayFlag {
            display: none;
          }
        }
        .ReviewSocialIcons {
          display: none;
        }
      }
    }
  }
  .spp_reviews #BVRR_SubmissionContainer {
    padding: 0 15px 0 15px;
    .formbody_BVRR {
      .overallratingfrom_BVRR {
        margin-bottom: 25px;
        span {
          margin-bottom: 10px;
          margin-left: 20px;
          padding-top: 4px;
          span {
            margin: 0;
            padding: 0;
          }
        }
        #vote_wrapper {
          float: right;
          #ratingvalue {
            display: none;
          }
        }
      }
      .recommendationfrom_BVRR {
        margin-bottom: 25px;
        .wouldyourecommendthis_BVRR {
          margin-bottom: 10px;
          margin-left: 10px;
          width: auto;
        }
      }
      .sampleratingfrom_BVRR {
        margin-bottom: 0px;
        .receiveproductsample_BVRR {
          margin-bottom: 10px;
          margin-left: 10px;
          width: auto;
        }
      }
      .reviewtitlefrom_BVRR {
        .titleinput_BVRR {
          width: 98%;
        }
        .titleofreview {
          margin-bottom: 10px;
        }
      }
      .fullreviewtitlefrom_BVRR {
        margin-bottom: 25px;
        span {
          margin-bottom: 10px;
        }
        .fullreviewinput_BVRR {
          width: 98%;
        }
      }
      .yourdetailsfield_BVRR {
        margin-bottom: 25px;
        .customerdetails_BVRR {
          margin-bottom: 10px;
        }
        .customerdetailsinput_BVRR {
          width: 98%;
        }
        .customerdetailselect_BVRR {
          width: 98%;
          padding: 0 15px 0 0;
        }
      }
    }
    .detailsfooter_BVRR {
      .buttonsid_BVRR {
        width: 98%;
        .button_BVRR,
        .cancelbutton_BVRR {
          width: 98%;
          margin-bottom: 25px;
        }
      }
      .footerlinks_BVRR {
        float: none;
        text-align: center;
        line-height: 28px;
        display: block;
        width: auto;
        a {
          display: block;
        }
      }
    }
  }
  .DisplayContent_BVRR .SubmissionPopupContainer_BV .BVRRMobileUserReviewCustomBody {
    display: block;
    clear: both;
    line-height: 1.5em;
    font-size: 13px;
    .BVRRMobileUserCustomBody {
      width: auto;
      float: none;
      display: block;
      margin-bottom: 20px;
      padding-top: 7px;
      .UserNickName_BVRR {
        font-size: 15px;
        margin-bottom: 5px;
      }
      .ContextdataContainer_BVRR {
        div {
          display: inline;
          margin-left: 15px;
          span {
            vertical-align: top;
          }
          span.DatavaluePrefixBold_BVRR {
            font-weight: bold;
          }
        }
      }
    }
  }
}

.DatavaluePrefix_BVRR.skincorn {
  display: none;
}

.DatavaluePrefix_BVRR.skincorn.breakOuts.breakOuts1,
.DatavaluePrefix_BVRR.skincorn.redness.redness2,
.DatavaluePrefix_BVRR.skincorn.darkSpots.darkSpots3,
.DatavaluePrefix_BVRR.skincorn.linesWrinkles.linesWrinkles4,
.DatavaluePrefix_BVRR.skincorn.pores.pores5,
.DatavaluePrefix_BVRR.skincorn.agePrevention.agePrevention6,
.DatavaluePrefix_BVRR.skincorn.clarityDullness.clarityDullness7,
.DatavaluePrefix_BVRR.skincorn.dehydration.dehydration8,
.DatavaluePrefix_BVRR.skincorn.makeupApplication.makeupApplication9 {
  display: inline;
}

.DatavaluePrefix_BVRR.skintype {
  display: none;
}

.DatavaluePrefix_BVRR.skintype.type1.skintype1,
.DatavaluePrefix_BVRR.skintype.type2.skintype2,
.DatavaluePrefix_BVRR.skintype.type3.skintype3,
.DatavaluePrefix_BVRR.skintype.type4.skintype4 {
  display: inline;
}

.DatavaluePrefix_BVRR.gender {
  display: none;
}

.DatavaluePrefix_BVRR.gender.male.male1,
.DatavaluePrefix_BVRR.gender.female.female1 {
  display: inline;
}

.ReviewDisplayContentSample_BVRR.BVsample {
  display: none;
}

.ReviewDisplayContentSample_BVRR.BVsample.Yes.yeslabel,
.ReviewDisplayContentSample_BVRR.BVsample.No.nolabel {
  display: inline;
}

.DatavaluePrefix_BVRR.lengthUse {
  display: none;
}

.DatavaluePrefix_BVRR.lengthUse._less1year.length1,
.DatavaluePrefix_BVRR.lengthUse._1to5years.length2,
.DatavaluePrefix_BVRR.lengthUse._6to10years.length3,
.DatavaluePrefix_BVRR.lengthUse._11to25years.length4,
.DatavaluePrefix_BVRR.lengthUse._lifetime.length5 {
  display: inline;
}

.DatavaluePrefix_BVRR.age {
  display: none;
}

.DatavaluePrefix_BVRR.age._YoungerThan18.age1,
.DatavaluePrefix_BVRR.age._18to21.age,
.DatavaluePrefix_BVRR.age._22to30.age3,
.DatavaluePrefix_BVRR.age._31to40.age4,
.DatavaluePrefix_BVRR.age._41to50.age5,
.DatavaluePrefix_BVRR.age._51to64.age6,
.DatavaluePrefix_BVRR.age._65plus.age7,
.DatavaluePrefix_BVRR.age._noTelling.age8 {
  display: inline;
}

.select-wrapper_BVRR {
  height: 37px;
  line-height: 47px;
  padding: 0 0 0 2px;
  position: relative;
  overflow: hidden;
  cursor: pointer !important;
  display: inline-block;
  zoom: 1;
  border: 1px solid #dbdbdb;
  width: 262px !important;
  vertical-align: middle;
  text-align: right;
  background: url('http://clinique.ugc.bazaarvoice.com/static/3813/iconDropDownArrow.png') no-repeat 5% 15px;
}

.select-wrapper_BVRR select {
  line-height: 47px;
  border-width: 0;
  width: 100%;
  height: 35px;
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
  filter: alpha(opacity=0);
  -moz-opacity: 0;
  background: none;
  cursor: pointer !important;
  margin: 0;
  padding: 0;
  vertical-align: baseline;
}

.select-wrapper_BVRR .holder_BVRR {
  width: 90% !important;
  height: 37px;
  line-height: 35px;
  padding: 0 5px 0 0;
  text-overflow: ellipsis;
  display: block;
  overflow: hidden;
  white-space: nowrap;
  cursor: pointer !important;
  text-indent: 12px;
  -webkit-user-select: none;
}

.select-wrapper_BVRR select {
  margin: 0;
  position: absolute;
  z-index: 2;
  cursor: pointer;
  outline: none;
  opacity: 0;
  _nofocusline: expression(this.hideFocus=true);
  -ms-filter: 'progid:DXImageTransform.Microsoft.Alpha(Opacity=0)';
  filter: alpha(opacity=0);
  -khtml-opacity: 0;
  -moz-opacity: 0;
}

.section-esearch {
  #search-wrapper {
    #summary {
      .summary-search-form {
        input.search-term {
          background: url('/sites/clinique/themes/cl_base/template_api/gnav_search_v1/icon-search.png') no-repeat scroll
            2% center rgba(0, 0, 0, 0);
          padding: 0 10px;
        }
        input.search-again {
          right: auto;
          left: 0;
        }
      }
    }
  }
}

html.ie8 {
  #search-wrapper {
    #summary {
      .search-again {
        input.search-term {
          border: 1px solid #cccccc;
          padding: 9px 10px 0 10px;
          background: none;
        }
        input.search-again {
          cursor: pointer;
          background: url('/sites/clinique/themes/cl_base/template_api/gnav_search_v1/icon-search.png') no-repeat scroll
            2% center;
          left: 5px;
        }
      }
    }
  }
}
