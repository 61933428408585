.pc_spp_view {
  .page-spp.bv-widgets {
    border-bottom: 1px solid $color-shade-grey;
  }
}

#pr-spp-snippet {
  .p-w-r {
    .pr-snippet-stars-reco-inline {
      &.pr-snippet-compact {
        .pr-snippet-stars-reco-stars,
        .pr-snippet-stars-reco-reco {
          @include breakpoint($ab-small-down) {
            text-align: center;
            float: none;
          }
        }
      }
      .pr-snippet-read-and-write {
        .pr-snippet-review-count {
          float: right;
          padding: 0 4px 0 6px;
        }
        a {
          padding: 0px 5px 0 3px;
        }
      }
    }
  }
}

.device-pc {
  .spp_page_wrap {
    .module-spp-detail {
      .container {
        .col1 {
          .spp-share-container {
            margin-top: auto;
          }
        }
      }
    }
  }
}

.write_a_review__container {
  #pr-write {
    .p-w-r {
      .tag-group {
        .pr-label-control {
          text-align: right;
        }
        .pr-btn-add-tag {
          text-align: right;
          clear: both;
        }
      }
      #pr-war-form {
        .pr-btn-default {
          text-align: right;
          clear: both;
          &.pr-btn-fileinput {
            @include swap_direction(margin, 10px 0);
          }
        }
      }
      .pr-submit {
        text-align: right;
        .pr-pull-left {
          float: right !important;
        }
      }
      .thank-you-page {
        .headline,
        .col-sm-12 {
          text-align: right;
        }
        .header {
          .title,
          .subtitle,
          h5 {
            text-align: right;
          }
        }
        .pr-rating-stars {
          float: right;
        }
        #war-ty-pros {
          text-align: right;
        }
      }
    }
    .form-group {
      label {
        float: right !important;
        clear: both;
        display: block;
        width: 100% !important;
        text-align: right;
      }
      .pr-helper-text,
      .tag-group {
        text-align: right;
        clear: both;
      }
      .pr-rating-stars {
        float: right;
        clear: both;
        padding-bottom: 20px;
      }
      select {
        background: url('/media/images/stars/down-arrow.png') no-repeat scroll 1% 5px transparent;
      }
    }
  }
}

#pr-review-display {
  .p-w-r {
    .pr-review-display {
      .pr-rd-star-rating {
        float: right;
      }
      .pr-review {
        .pr-rd-header {
          .pr-rd-review-headline {
            float: right;
            text-align: right;
          }
        }
        .pr-accordion {
          .pr-accordion-btn {
            padding: 0;
          }
        }
        .pr-rd-footer {
          .pr-rd-flag-review-container {
            width: auto;
          }
          .pr-helpful-no,
          .pr-helpful-yes {
            .pr-helpful-count {
              &:before {
                margin-right: 10px;
              }
            }
          }
          .pr-helpful-yes {
            .pr-helpful-count {
              &:before {
                content: 'כן';
              }
            }
          }
          .pr-helpful-no {
            .pr-helpful-count {
              &:before {
                content: 'לא';
              }
            }
          }
        }
      }
      .pr-rd-content-block {
        text-align: right;
      }
      .pr-rd-def-list {
        dt,
        dd {
          @include swap_direction(padding, 0);
        }
      }
      @include breakpoint($ab-small-down) {
        .pr-rd-review-tag,
        .pr-rd-right {
          clear: both;
        }
        .pr-rd-review-position,
        .pr-rd-to-top,
        .pr-rd-pagination {
          text-align: center;
          padding: 0;
          border: 0;
        }
      }
      .pr-modal-content {
        .pr-flag-review-fieldset {
          text-align: right;
        }
        #pr-flag-reviews {
          input[type='radio'] {
            margin-left: 5px !important;
          }
        }
      }
      .pr-flag-review {
        radiogroup {
          .pr-form-group {
            float: right;
          }
        }
      }
      .pr-rd-main-footer {
        @include breakpoint($ab-small-down) {
          margin-bottom: 15px;
        }
        .pr-rd-content-block {
          .pr-rd-to-top {
            padding-left: 30%;
            @include breakpoint($ab-small-down) {
              padding-left: 0;
              padding-right: 40%;
            }
          }
        }
      }
      .pr-rd-main-header {
        .pr-rd-review-header-contents {
          width: 50%;
          left: 0;
          right: auto;
          &:before {
            content: 'סנן לפי :';
            vertical-align: middle;
          }
        }
        .pr-rd-main-header-search-sort {
          width: 49%;
          @include breakpoint($ab-small-down) {
            width: 100%;
          }
        }
        .pr-rd-review-header-sorts {
          width: 54%;
          left: 195px;
          right: auto;
          @include breakpoint($ab-small-down) {
            width: 100%;
          }
          .pr-rd-sort-group {
            float: right;
          }
        }
        .pr-multiselect-options {
          left: auto;
        }
        .pr-rd-sort {
          background: url('/media/images/stars/down-arrow.png') no-repeat scroll 0% 5px transparent;
          width: 36%;
          @include breakpoint($ab-small-down) {
            width: 71%;
            background: url('/media/images/stars/down-arrow.png') no-repeat scroll 0% 0 transparent;
          }
        }
        .pr-rd-main-header-search {
          padding-left: 0;
        }
      }
      &.pr-rd-display-desktop {
        .pr-rd-sort-group {
          &:before {
            content: 'מיין לפי :';
          }
        }
        .pr-rd-main-header-with-filters {
          .pr-rd-review-header-contents {
            text-align: right;
          }
          .pr-rd-main-header-search-sort {
            .pr-rd-main-header-sorts-w-search {
              float: right;
            }
          }
          .pr-rd-search-container {
            float: left;
            .pr-rd-search-reviews-input input {
              padding: 5px 40px 5px 5px;
            }
          }
        }
        .pr-rd-review-tag,
        .pr-rd-right {
          float: right;
        }
        .pr-rd-helpful-text,
        .pr-rd-helpful-action {
          margin-right: 0;
          margin-left: 10px;
        }
        .pr-rd-side-content-block {
          left: -30%;
          right: auto;
        }
        .pr-rd-review-position,
        .pr-rd-to-top {
          float: right;
        }
      }
      .pr-rd-sort-group {
        &:before {
          content: 'מיין לפי :';
        }
      }
      .pr-multiselect {
        .pr-multiselect-button {
          padding: 8px 4px 8px 27px;
          margin: 5px 10px 5px 0;
        }
        .pr-caret-icon {
          left: 0;
          right: auto;
        }
        .pr-multiselect-options {
          span {
            @include breakpoint($ab-small-down) {
              margin-left: 16%;
            }
          }
        }
      }
    }
    .pr-review-snapshot {
      .pr-review-snapshot-block {
        float: right;
      }
    }
    .pr-review-snapshot-simple {
      .pr-snippet-read-and-write {
        a.pr-snippet-write-review-link {
          left: 0;
          right: auto;
        }
      }
    }
  }
}

.reviews {
  .p-w-r {
    .pr-snippet-stars-reco-inline {
      &.pr-snippet-minimal {
        .pr-snippet-read-and-write {
          float: right;
        }
      }
      .pr-snippet-read-and-write {
        a.pr-snippet-write-review-link {
          @include breakpoint($ab-small-down) {
            vertical-align: top;
          }
        }
      }
    }
  }
}

#pr-write {
  .p-w-r {
    .tag-group {
      .pr-label-control {
        white-space: initial;
      }
    }
  }
}

.mpp-grid {
  .reviews {
    .p-w-r {
      .pr-snippet {
        .pr-category-snippet__rating,
        .pr-category-snippet__total,
        .pr-snippet-stars-container,
        .pr-snippet-read-and-write {
          display: block;
          margin-top: 0;
        }
        .pr-snippet-stars-container {
          float: none;
        }
      }
    }
  }
}

#power_review_container {
  .product-full__details {
    .pr-sub-line {
      text-align: right;
    }
  }
}

.mpp-product {
  .p-w-r {
    .pr-snippet {
      div {
        @include breakpoint($ab-small-down) {
          vertical-align: bottom;
        }
      }
    }
  }
}

#mpp-product-grid {
  .mpp-box {
    .reviews {
      padding: 0;
      .p-w-r {
        .pr-snippet {
          .pr-snippet-stars-container {
            padding: 0;
            display: flex;
          }
          .pr-snippet-stars-png {
            float: right;
          }
        }
        .pr-snippet-stars-reco-inline {
          .pr-snippet-read-and-write {
            .pr-snippet-review-count {
              padding: 0;
            }
          }
        }
      }
    }
  }
  &.view-large {
    .reviews {
      .p-w-r {
        .pr-snippet-stars-reco-inline {
          padding: 0 53px;
        }
      }
    }
  }
}

#BVRR_PaginationContainer {
  display: none;
}

.spp_reviews {
  #BVRRSortContainer {
    display: none;
  }
}
