@if $password_strength {
  #signin {
    .password-field {
      &__info {
        @media #{$medium-up} {
          &:before {
            #{$rdirection}: 50%;
            top: 100%;
          }
        }
      }
    }
  }
  .profile-password-update {
    &__button {
      #foreground-node & {
        &:first-child {
          margin: 5px;
        }
      }
    }
    &__rules {
      li {
        @media #{$medium-up} {
          font-size: 12px;
        }
      }
    }
  }
  #password_reset {
    .password-field {
      &__info {
        @media #{$medium-up} {
          #{$ldirection}: 24%;
          &:before {
            #{$rdirection}: -4%;
          }
        }
      }
    }
  }
  #gnav_signin {
    .signin_container {
      .row {
        overflow: visible;
      }
    }
    .password-field {
      &__info {
        @media #{$medium-up} {
          width: 25%;
        }
      }
    }
  }
}
