.section-clinicalreality {
  .LPMcontainer {
    display: none !important;
  }
  #INDbtnWrap {
    display: none;
  }
}

#clinical-reality {
  #header {
    .outer-wrap {
      direction: rtl;
      overflow: inherit;
    }
  }
  .outer-wrap {
    direction: ltr;
    overflow: hidden;
  }
}

#landing,
#diagnostics {
  .clinical-reality {
    .outer-wrap {
      direction: ltr;
    }
    .clinical-reality__results-intro {
      &--last-taken {
        float: right;
      }
    }
  }
}

.clinical-reality {
  .outer-wrap {
    direction: ltr;
  }
  &__intro {
    &--analysis-content {
      left: 0;
    }
  }
  &__modules {
    overflow: hidden;
    .clinical-reality__recommended-products {
      &--name {
        @include start-breakpoint($small-m) {
          left: 20px;
        }
        @include start-breakpoint($medium-m) {
          left: 48px;
        }
        right: inherit;
      }
      &--description {
        @include start-breakpoint($small-m) {
          left: 0;
        }
        @include start-breakpoint($medium-m) {
          left: 20px;
          width: 55%;
        }
      }
      &--mix {
        @include start-breakpoint($small-m) {
          right: 20px;
        }
        @include start-breakpoint($medium-m) {
          right: 48px;
        }
        left: inherit;
      }
      .clinical-reality__recommended-products {
        &--description-content {
          text-align: right;
        }
      }
    }
  }
  &__button-options {
    float: left;
  }
  &__module--title {
    text-align: right;
  }
  &__save-results--right {
    text-align: right;
  }
  &__results-intro {
    &--title,
    &--description p {
      text-align: right;
    }
    &-button-options {
      .clinical-reality--button {
        margin-right: 20px;
        margin-left: 0;
      }
    }
  }
  &__mpp--product {
    .product-image {
      img {
        left: 50%;
      }
    }
    .product-info {
      float: right;
      text-align: right;
    }
    .product-add-to-bag {
      left: 50%;
    }
  }
  &__recommended-products {
    &--container {
      a.product-add-to-bag {
        @include start-breakpoint($small-m) {
          margin-left: 0;
        }
        @include start-breakpoint($medium-m) {
          margin-left: 15px;
        }
        @include start-breakpoint($medium-max) {
          margin-left: 0;
        }
        float: right;
      }
    }
  }
}

.clinical-reality__modules--recommended-products {
  .flexslider {
    .ccr-flexslider--nav {
      right: 20px;
    }
  }
  .clinical-reality__button-options {
    float: right;
  }
}

.clinical-reality__skin-products--products {
  .ccr-flexslider {
    .flex-direction-nav {
      a {
        color: transparent;
      }
      .flex-prev {
        left: inherit;
        right: 0;
      }
    }
    &.clinical-reality-slider-active {
      .ccr-flexslider--nav {
        left: 0;
      }
    }
  }
}

.ccr-flexslider {
  &--left-nav {
    transform: rotate(180deg);
    background-position: left -12px;
    width: 9.6px;
  }
  &--status {
    direction: rtl;
    unicode-bidi: bidi-override;
  }
  &.clinical-reality-slider-active {
    .ccr-flexslider--nav {
      @include start-breakpoint($medium-m) {
        left: 48px;
      }
      right: inherit;
    }
  }
}
