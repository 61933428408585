.elc-product-full {
  .elc-vto-foundation {
    .elc-vto {
      &-perfect-shade-label {
        font-size: 14px;
        width: auto;
      }
      &-foundation-shade-grid {
        &.vto-foundation-lip {
          .elc-vto-product-name {
            font-weight: bold;
            height: 67px;
          }
        }
      }
      &-shade-grid-cta-container {
        float: none !important;
      }
      &-shade-info-right-container {
        #{$rdirection}: 50px;
      }
      &-see-all-shades,
      &-see-perfect-shades {
        &.elc-button {
          [data-component] & {
            border: 0;
            text-decoration: underline;
          }
        }
      }
      &-custom-shade-picker {
        width: 100%;
      }
    }
    .vto-foundation-perfect-shade {
      .slick-slide:nth-child(2) {
        flex-basis: 26%;
      }
    }
    .elc-add-to-bag-button,
    .elc-product-notify-me-button {
      height: 48px;
      line-height: 48px;
    }
  }
  .elc-price-formatted-wrapper {
    .elc-price-formatted {
      font-weight: bold;
      margin-#{$rdirection}: 20px;
    }
  }
}
