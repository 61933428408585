/* NOTIFICATIONS
 - make changes under the BRAND SPECIFICS section
---------------------------------------------- */

/* DEFAULT CONTAINER STYLE */
#bt_notification div.bt-content {
  position: relative;
  border: 1px solid black;
  padding: 15px;
  background-color: white;
  z-index: 1001;
  font-size: 12px;
  line-height: 16px;
}

#bt_notification div.bt-content a.bt-close-link {
  position: absolute;
  top: 10px;
  #{$rdirection}: 10px;
}
/* CUSTOM POSITIONING */
#bt_notification div.bt-bottom-right {
  position: fixed;
  bottom: 0px;
  #{$rdirection}: 10px;
  width: 250px;
  border-bottom-width: 0;
  z-index: 108000;
}

#bt_notification div.bt-bottom-full {
  position: fixed;
  bottom: 0px;
  #{$ldirection}: 0px;
  #{$rdirection}: 0px;
  border-bottom-width: 0;
}
/* DEVICES */
#bt_notification div.bt-content-mobile {
  padding: 10px;
}

#bt_notification div.bt-content-mobile a.bt-close-link {
  display: none;
}
/* BRAND SPECIFICS @JM */
#bt_notification div.bt-content {
  border-color: #000;
  border-width: 10px;
  padding: 0px;
}

#bt_notification {
  div.bt-content {
    .bt-pp-section,
    .bt-cookie-section {
      padding: 15px;
    }
    .bt-pp-section {
      background-color: #930808;
      color: #fff;
      h3,
      h4 {
        color: #fff;
      }
    }
    .bt-cookie-section {
      h3,
      h4 {
        color: #222;
      }
    }
    a {
      border: 0;
    }
    .btn-wrap {
      margin-top: 10px;
      border: 1px solid #fff;
      a {
        width: 100%;
        font-weight: normal;
        padding: 0 3px;
        line-height: 25px;
        font-size: 11px;
        height: auto;
      }
    }
    &.bt-bottom-right {
      padding: 0px;
    }
  }
}

#bt_notification div.bt-content a.bt-close-link {
  display: block;
  width: 15px;
  height: 15px;
  background: url('/media/export/images/cookies/close_x.png') 50% 50% no-repeat;
  text-indent: -9999em;
}
/* SETTINGS
 - make changes under the BRAND SPECIFICS section
---------------------------------------------- */

/* DEFAULT CONTAINER STYLE */
#bt_settings div.bt-content {
  border: 1px solid black;
  padding: 15px;
  background-color: white;
  z-index: 1001;
  font-size: 12px;
  line-height: 16px;
  margin-bottom: 1.5em;
}

#bt_settings div.bt-content div.bt-intro {
}

#bt_settings div.bt-content div.bt-intro div.bt-intro-copy {
}

#bt_settings div.bt-content div.bt-intro div.bt-intro-copy p {
  margin-top: 1em;
}
/* TOGGLE SLIDER */
#bt_settings div.bt-content div.slide,
#bt_settings div.bt-content ul.slide-toggle {
  position: relative;
  overflow: hidden;
  width: 525px;
}

#bt_settings div.bt-content div.slide {
  margin: 20px 0;
}

#bt_settings div.bt-content ul.slide-toggle {
  list-style: none;
  margin: 0;
  padding: 0;
  background: transparent url('/media/export/images/cookies/all-off.png') 50% 100% no-repeat;
  background-size: contain;
}

#bt_settings div.bt-content div.mask {
  position: absolute;
  overflow: hidden;
  top: 0px;
  #{$ldirection}: 0px;
  width: 0px;
  border-#{$rdirection}: 1px solid #eee;
}

#bt_settings div.bt-content div.mask ul.slide-toggle {
  background-image: url('/media/export/images/cookies/all-on.png');
}

#bt_settings div.bt-content div.mask div.drag {
  position: absolute;
  bottom: 12px;
  #{$rdirection}: 70px;
  width: 26px;
  height: 11px;
  background: transparent url('/media/export/images/cookies/icon_arrow.png') 50% 100% no-repeat;
}

#bt_settings div.bt-content ul.slide-toggle li {
  cursor: pointer;
  float: #{$ldirection};
  width: 175px;
  height: 138px;
}

#bt_settings div.bt-content ul.slide-toggle li.middle {
}

#bt_settings div.bt-content ul.slide-toggle li.selected {
}

#bt_settings div.bt-content ul.slide-toggle li span {
  display: block;
  font-weight: bold;
  text-align: center;
  margin: 5px;
}
/* PRIVACY INFORMATION */
#bt_settings div.bt-content .bt-privacy-info {
  display: none;
  overflow: hidden;
}

#bt_settings div.bt-content .bt-privacy-info.selected {
  display: block;
}

#bt_settings div.bt-content .bt-privacy-info h3 {
  @include swap_direction(padding, 1em 1em 1em 40px);
  background-position: 10px 50%;
  background-repeat: no-repeat;
}

#bt_settings div.bt-content .bt-privacy-info div.bt-privacy-will h3 {
  background-image: url('/media/export/images/cookies/icon_safe_lock.png');
}

#bt_settings div.bt-content .bt-privacy-info div.bt-privacy-willnot h3 {
  background-image: url('/media/export/images/cookies/icon_unsafe_lock.png');
}

#bt_settings div.bt-content .bt-privacy-info div {
  width: 48%;
  margin-#{$rdirection}: 2%;
  float: #{$ldirection};
}

#bt_settings div.bt-content .bt-privacy-info div ul {
  list-style-type: none;
  padding-#{$ldirection}: 0;
  margin: 1em;
}

#bt_settings div.bt-content .bt-privacy-info div ul li {
  margin: 0.8em 0;
  padding-#{$ldirection}: 30px;
  background-position: 0 50%;
  background-repeat: no-repeat;
}

#bt_settings div.bt-content .bt-privacy-info div.bt-privacy-will ul li {
  background-image: url('/media/export/images/cookies/icon_safe_thick.png');
}

#bt_settings div.bt-content .bt-privacy-info div.bt-privacy-willnot ul li {
  background-image: url('/media/export/images/cookies/icon_unsafe_thick.png');
}
/* CUSTOM POSITIONING */

/* DEVICES */
#bt_settings div.bt-content-mobile ul.slide-toggle {
  background-image: url('/media/export/images/cookies/m_all-off.png');
}

#bt_settings div.bt-content-mobile div.mask ul.slide-toggle {
  background-image: url('/media/export/images/cookies/m_all-on.png');
}

#bt_settings div.bt-content-mobile div.slide {
  margin: 20px auto;
}

#bt_settings div.bt-content-mobile div.slide,
#bt_settings div.bt-content-mobile ul.slide-toggle {
  width: 288px;
}

#bt_settings div.bt-content-mobile ul.slide-toggle li {
  width: 96px;
  height: 159px;
}

#bt_settings div.bt-content-mobile div.mask div.drag {
  #{$rdirection}: 36px;
}

#bt_settings div.bt-content-mobile ul.slide-toggle li span {
}

#bt_settings div.bt-content-mobile .bt-privacy-info div {
  width: 100%;
  float: none;
}
/* BRAND SPECIFICS */
#bt_settings div.bt-content {
  border-color: #000;
  border-width: 2px;
  border-radius: 6px;
  -webkit-border-radius: 6px;
  -moz-border-radius: 6px;
}

#bt_settings div.bt-content-desktop {
  padding: 2em;
  height: 100%;
}

#bt_settings div.bt-content div.bt-intro h2 {
  padding: 0;
  @include swap_direction(margin, 0 0 1em 0);
  border: none;
  text-transform: none;
  color: #000;
}

#bt_settings div.bt-content ul.slide-toggle li span {
  display: none;
}

#bt_settings div.bt-content .bt-privacy-info h3 {
  background-color: #f5f5f5;
}

#bt_settings div.bt-content-mobile div.slide,
#bt_settings div.bt-content-mobile ul.slide-toggle {
  width: 246px;
}

#bt_settings div.bt-content-mobile ul.slide-toggle li {
  width: 82px;
  height: 136px;
}

#bt_settings div.bt-content-mobile div.mask div.drag {
  #{$rdirection}: 30px;
  bottom: 11px;
}
