html.no-js .nojs_hidden,
html.js .js_hidden {
  display: none;
}

#shipping {
  #checkout_shipmethod {
    .ship-method-group-label {
      margin: 0 0 10px 0;
      input[type='radio'] {
        float: none;
        vertical-align: middle;
        margin: 0 10px 0 10px;
        &:first-child {
          margin: 0 10px 0 0px;
        }
      }
    }
  }
}

// GWP/MYOG styles start here
.wp-sample-page-mb {
  .wp_messages {
    font-size: 16px;
    text-align: center;
    padding: 2% 10%;
  }
  .border-bottom {
    border-bottom: 1px solid #e3e4e5;
    margin-bottom: 8%;
  }
  .no-top_border {
    border-top: none;
  }
  .prod-desc {
    margin-top: 5%;
  }
  .samples_content img,
  .product-img img {
    width: 100%;
  }
  .wp-samples-contrl {
    margin: 10% 0;
  }
  .wp-lable-mb {
    margin: 3% 0;
  }
  .checkbox-disable {
    color: #9e9ea0;
    cursor: default;
  }
  .button-disable {
    background-color: #cccccc;
    cursor: default;
  }
  .no-offer-msg {
    margin-top: 20%;
    text-align: center;
  }
  .wp-footer-top {
    margin: 1% 0 3% 0;
    text-align: left;
  }
  .wp-footer-bottom {
    margin-bottom: 5%;
    text-align: left;
  }
}

#viewcart {
  .wp_message {
    font-size: 16px;
    color: #000;
  }
  .wp-name {
    font-size: 10px;
  }
  .wp-desc {
    margin-bottom: 1%;
    color: #231f20;
    font-weight: bold;
  }
  .offer-txt {
    color: #231f20;
  }
  .myog-remove {
    margin-top: 5%;
    padding-left: 35%;
  }
  .myog-price {
    padding-left: 35%;
  }
  .loading-img {
    position: absolute;
    padding: 0 0 0 1%;
  }
  #main {
    #viewcart-panel {
      #viewcart-buttons {
        &.hasPaypal {
          display: block;
          .continue-buttons {
            .continue-shopping,
            .continue-checkout {
              display: none !important;
            }
          }
          .viewcart-paypal-button {
            text-align: $ldirection;
            .terms-conditions {
              &-txt {
                display: inline;
                a {
                  display: inline;
                }
              }
            }
          }
        }
      }
    }
  }
  .viewcart-samples {
    &__content {
      .slick-list {
        overflow: auto;
      }
    }
  }
}

#review {
  .wp_message {
    font-size: 16px;
    color: #000;
  }
  .wp-name {
    font-size: 10px;
  }
  .wp-desc {
    margin-bottom: 1%;
    color: #231f20;
    font-weight: bold;
  }
  .offer-txt {
    color: #231f20;
  }
  .myog-remove {
    margin-top: 5%;
    padding-left: 35%;
  }
  .loading-img {
    position: absolute;
    padding: 0 0 0 1%;
  }
}
// GWP/MYOG styles ends here

.offer-code-panel {
  .offer-code__submit {
    a.offer-code__see-all-offers {
      margin: 10px 0 0 0;
      float: left;
      width: 100%;
      text-align: center;
    }
  }
}

.overlay-container.mobile-offer-overlay {
  top: 0px !important;
  .module {
    padding: 10px 0;
    .offer-layout-mobile {
      border-bottom: none;
    }
  }
}

#top {
  #header {
    .block-template-site-logo-responsive-v1 {
      width: 100%;
    }
    .block-template-gnav-cart-v1 {
      width: 15%;
    }
  }
}

#viewcart-shopping-bag-panel {
  .product__desc-info {
    .color {
      display: none;
    }
  }
}

.ingredients_container {
  list-style: none;
}

body {
  &.section-foundation-finder {
    #foundation-finder-quiz {
      .ff-quiz {
        &__slide {
          &.ff-quiz {
            &__slide {
              &--7,
              &--8 {
                background-size: cover;
              }
            }
          }
        }
      }
    }
  }
}

/************************************************
* !important rule is used in the below section of
code to override already existing !important rule  *
************************************************/
.spc_enabled {
  #diagnostics {
    .diagnostics-header {
      .outer-wrap.clinical-reality__module-full-width {
        padding: 20px !important;
      }
    }
  }
  .clinical-reality,
  #clinical-reality {
    .clinical-reality-recommended-products-v1.outer-wrap {
      padding: 0 20px !important;
    }
  }
}
/* CL Randomizer Game */
section.clinique-spinner section#game-win-box {
  .win-box-btns {
    .right-btn-box,
    .single-btn-box {
      .button {
        @include breakpoint($small-max) {
          @include swap_direction(padding, 5px 10px);
        }
      }
    }
  }
}

@include breakpoint($small-max) {
  section {
    &.clinique-spinner section#game-win-box {
      .win-image,
      .win-image--mobile {
        height: 200px;
      }
    }
  }
}

section.clinique-spinner {
  .game-spinner {
    @include breakpoint($medium-max) {
      @include swap_direction(margin, 0 7px);
    }
    @include breakpoint($small-max) {
      @include swap_direction(margin, 0 3px);
    }
  }
}

.random-gift-picker-formatter {
  .pop-up-box {
    @include breakpoint($medium-max) {
      max-height: 684px;
    }
    @include breakpoint($xlarge-max) {
      max-height: 684px;
    }
    &--game-error,
    &--game-unavailable {
      .pop-up-box-inner {
        max-width: 90%;
        border: 6px solid $color-spin-pink;
        outline: 2px solid $color-spin-pink;
        outline-offset: -11px;
        font-size: 28px;
      }
    }
  }
}

.checkout {
  &__sidebar {
    a {
      &.disabled {
        background: $color-grey;
      }
    }
  }
}

.device-mobile {
  .popup-offer-v1 {
    .outer-wrap {
      background: none repeat scroll 0 0 $color-white;
      border: 2px solid $color-black;
      height: 353px;
      overflow: hidden;
      padding: 20px 20px 15px;
      position: fixed;
      top: 20%;
      z-index: 10000;
    }
    .popup-offer-close {
      background: transparent url(/sites/clinique/themes/cl_base/img/icon-close.png) no-repeat scroll 0 0/15px 15px;
      cursor: pointer;
      height: 15px;
      position: absolute;
      right: 5px;
      text-align: left;
      top: 5px;
      width: 15px;
    }
  }
}
.section-giftwithpurchase {
  .sd-product-grid {
    .elc-grid-container {
      margin: 0;
      .rcmPW,
      .slick-slider {
        margin-bottom: 0 !important;
      }
    }
  }
}

#signin {
  input[type='checkbox'] {
    margin: 0;
  }
  .privacy-form-container {
    .loyalty {
      &__rewards {
        font-family: $base-bold-font-family;
        font-weight: 400;
      }
    }
  }
  .email_promotions {
    span {
      &.label-content {
        width: auto;
        display: inline-block;
        @include swap_direction(margin, -18px 0 0 30px);
        font-family: $base-font-family;
      }
    }
  }
}

.registration_content {
  .info-message-gdpr {
    display: inline-flex;
  }
}

#registration {
  &.device-mobile {
    #registration-wrapper {
      .loyalty_form_container {
        span {
          &.loyalty-disclaimer {
            width: 94%;
          }
        }
      }
    }
    .registration_content {
      label {
        width: 94%;
        .label-content {
          font-family: $base-font-family;
          font-size: 14px;
        }
      }
    }
  }
}

.cs-page-section-formatter-v1 {
  ul {
    font-family: $base-font-family;
    font-size: 22px;
  }
}
